<template>
  <div class="relative flex flex-col h-full">
    <HeadAttributes />
    <slot name="header" />
    <div
      class="grow-[2] flex flex-col"
      :class="{
        [contentClass]: contentClass,
        [navStore.layoutBGColor]: navStore.layoutBGColor,
      }"
    >
      <slot name="content" />
    </div>
    <div v-show="progress === 0">
      <slot name="footer" />
    </div>
  </div>
</template>
<script setup lang="ts">
import HeadAttributes from '@/components/layout/HeadAttributes.vue';
import { useNav } from '~/stores/useNav';

const { progress } = useLoadingIndicator();

defineProps({
  contentClass: {
    type: String,
    required: false,
    default: '',
  },
});

const navStore = useNav();
</script>
